<template>
  <div class="json-editor">
    <textarea :id="editorId" ref="jsonEditor"></textarea>
  </div>
</template>

<script>
import CodeMirror from 'codemirror'
import 'codemirror/addon/lint/lint.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/monokai.css'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/addon/lint/lint'
import 'codemirror/addon/lint/json-lint'
require('script-loader!jsonlint')

export default {
  name: 'JsonEditor',
  setup(props) {
    return {
      editorId: `ID-${Math.random().toString(36).slice(6).toUpperCase()}`
    }
  },
  data() {
    return {}
  },
  props: {
    modelValue: { type: [Object, String], default: '' }
  },
  mounted() {
    const jsonEditor = CodeMirror.fromTextArea(document.querySelector(`#${this.editorId}`), {
      mode: { name: 'javascript', json: true },
      tabSize: 2,
      lineNumbers: true,
      lineWrapping: true,
      theme: 'monokai',
      lint: true,
      gutters: ['CodeMirror-lint-markers'],
      matchBrackets: true
    })
    this.jsonEditor = jsonEditor
    jsonEditor.on('change', (cm) => {
      this.$emit('changed', cm.getValue())
      this.$emit('update:modelValue', cm.getValue())
    })
    this.setValue()
  },
  beforeUnmount() {
    if (this.jsonEditor) this.jsonEditor.toTextArea()
  },
  methods: {
    getValue() {
      if (!this.jsonEditor) return ''
      return this.jsonEditor.getValue()
    },
    setValue() {
      if (!this.jsonEditor) return
      let val = this.modelValue
      if (typeof val === 'object') val = JSON.stringify(this.modelValue, null, 2)
      this.jsonEditor.setValue(val)
    }
  }
}
</script>

<style lang="scss">
.json-editor {
  height: 100%;
  position: relative;
}
.json-editor .CodeMirror {
  font-size: 16px;
  height: 100%;
  min-height: 300px;
  .CodeMirror-lines {
    line-height: 1.52;
  }
}
.json-editor .CodeMirror-scroll {
  min-height: 300px;
}
.json-editor .cm-s-rubyblue span.cm-string {
  color: #f08047 !important;
}
</style>
