<template>
  <div class="page-kv-create">
    <!-- <el-button
      style="margin-bottom: 20px"
      type="primary"
      icon="el-icon-arrow-left"
      @click="$router.go(-1)"
    >返回列表</el-button>-->
    <el-form ref="Form" :rules="rules" :model="form" label-position="right" label-width="124px">
      <el-form-item label="key" prop="key">
        <el-input :disabled="isEdit" v-model.trim="form.key" />
      </el-form-item>
      <el-form-item label="name" prop="name">
        <el-input v-model.trim="form.name" />
      </el-form-item>
      <el-form-item label="所属平台">
        <el-select style="width: 400px" v-model="suitableFor" multiple placeholder="请选择所属平台">
          <el-option label="iOS" value="iOS" />
          <el-option label="Android" value="Android" />
          <el-option label="PC" value="PC" />
          <el-option label="641" value="641" />
          <el-option label="MP" value="MP" />
        </el-select>
      </el-form-item>
      <el-form-item label="所属产品">
        <el-select style="width: 400px" v-model="form.belongsTo" placeholder="请选择所属产品">
          <el-option label="选股宝" value="xgb" />
        </el-select>
      </el-form-item>
      <el-form-item label="描述">
        <el-input :rows="2" v-model.trim="form.description" type="textarea" placeholder="请输入描述" />
      </el-form-item>
      <el-form-item label="是否私有">
        <el-switch v-model="form.is_private" active-text="私有" inactive-text="非私有" />
      </el-form-item>
      <el-form-item label="kv内容">
        <!-- <el-input class="json-area" type="textarea" autosize v-model="form.value"></el-input> -->
        <JsonEditor ref="jsonEditor" v-model="form.value" />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          icon="el-icon-s-promotion"
          @click="submitForm"
        >{{ isEdit ? '更新' : '发布' }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { createKVConfig, updateKVConfig, getKVConfig } from '@/domains/base-baoer'
import JsonEditor from '@/components/base/json-editor'

export default {
  name: 'KvCreate',
  components: { JsonEditor },
  data() {
    return {
      form: {
        key: '',
        name: '',
        belongsTo: 'xgb',
        suitable_for: '',
        is_private: false,
        description: null,
        value: ''
      },
      suitableFor: [],
      rules: {
        key: [{ required: true, message: '必填项', trigger: 'blur' }],
        name: [{ required: true, message: '必填项', trigger: 'blur' }]
      }
    }
  },
  computed: {
    kvid() {
      return +this.$route.params.id || 0
    },
    isEdit() {
      return Boolean(this.kvid)
    }
  },
  mounted() {
    if (this.kvid) this.fetchData()
  },
  methods: {
    fetchData() {
      getKVConfig(this.kvid)
        .then((res) => {
          this.form = res
          this.suitableFor = (res.suitable_for && res.suitable_for.split(',')) || []
          this.form.value = JSON.stringify(JSON.parse(res.value), null, 2)
          this.$nextTick(this.$refs.jsonEditor.setValue)
        })
        .catch((err) => {
          this.$message.error(JSON.stringify(err))
        })
    },
    submitForm() {
      this.form.suitable_for = this.suitableFor.join(',')
      this.$refs.Form.validate((valid) => {
        if (!valid) return
        try {
          if (!this.form.value) {
            this.$message.error('请填写kv内容')
            return
          }
          const result = JSON.parse(this.form.value)
          if (result) {
            if (this.isEdit) {
              this.updateKvconfig()
            } else {
              this.createKvconfig()
            }
          }
        } catch (e) {
          this.$message.error('JSON书写有误, 请仔细填写!')
        }
      })
    },
    createKvconfig() {
      createKVConfig(this.form)
        .then(() => {
          this.$message.success('创建成功 🎉')
          this.$router.push({ name: 'KVConfig' })
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    },
    updateKvconfig() {
      updateKVConfig(this.form.id, this.form)
        .then(() => {
          this.$message.success('更新成功 🎉')
          this.$router.push({ name: 'KVConfig' })
        })
        .catch((err) => {
          this.$message.error(err.message)
        })
    }
  }
}
</script>

<style lang="scss">
.page-kv-create {
  margin: 10px auto;
  max-width: 1080px;
  .json-area .el-textarea__inner {
    background: #272822;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
}
</style>
